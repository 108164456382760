import anime from 'animejs/lib/anime.es.js';

document.addEventListener("click", (e) => {

    if (e.target == null) {
        return;
    }

    /** @type {HTMLElement} */
    const htmlTarget = e.target;

    if (!htmlTarget.classList.contains("menu-item")) {
        return;
    }
});

document.addEventListener('load', () => {
    const timeline = anime.timeline({});

    timeline.add({
        targets: '#sector-span',
    })
});